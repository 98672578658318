import { Edit } from "react-admin";
import OrganizationForm from "./OrganizationForm";

const OrganizationEdit = (props) => {
  return (
    <Edit>
      <OrganizationForm {...props} />
    </Edit>
  );
};

export default OrganizationEdit;
