/* eslint-disable default-case */
/* eslint-disable import/no-anonymous-default-export */
export default {
  renderEndDate: (record) => {
    if (!record) return "-";
    if (record.type === "unlimited") return "-";
    else {
      const end = record.ended_at?.split("-");
      if (!end) return "-";
      return `${end[2]}/${end[1]}/${end[0]}`;
    }
  },
  transformLicense: (data) => {
    const start = new Date(data.started_at);
    data.started_at = start.toISOString().split("T")[0];

    // based on type
    switch (data.type) {
      case "6 months":
        const end1 = new Date(start.setMonth(start.getMonth() + 6));
        data.ended_at = end1.toISOString().split("T")[0];
        break;
      case "1 year":
        const end2 = new Date(start.setFullYear(start.getFullYear() + 1));
        data.ended_at = end2.toISOString().split("T")[0];
        break;
      case "unlimited":
        const end3 = new Date(start.setFullYear(start.getFullYear() + 100));
        data.ended_at = end3.toISOString().split("T")[0];
        break;
    }

    return data;
  },
};
