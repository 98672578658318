import { useState, useEffect } from "react";
import {
  FormTab,
  SelectArrayInput,
  Loading,
  useRecordContext,
} from "react-admin";
import dataProvider from "../../../../data-providers/data-provider";

export const TagsFormTab = (props) => {
  const [tags, setTags] = useState([]);

  const NameField = () => {
    const record = useRecordContext();
    return <span>{record.name}</span>;
  };

  // fetch tags
  useEffect(() => {
    dataProvider
      .getList("tags")
      .then(({ data }) => {
        setTags(data);
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <FormTab label='Tags' {...props}>
      {tags.length === 0 && <Loading />}

      {tags.length > 0 && (
        <SelectArrayInput
          label='Editeurs'
          source='editeurs'
          optionText={<NameField />}
          optionValue='name'
          choices={tags.filter((t) => t.type === "experienceEditeur")}
        />
      )}

      {tags.length > 0 && (
        <SelectArrayInput
          label='Casques compatibles'
          source='compatible_headsets'
          optionText={<NameField />}
          optionValue='name'
          choices={tags.filter((t) => t.type === "experienceHeadset")}
        />
      )}

      {tags.length > 0 && (
        <SelectArrayInput
          label='Catégories'
          source='labels'
          optionText={<NameField />}
          optionValue='name'
          choices={tags.filter((t) => t.type === "experienceTag")}
        />
      )}
    </FormTab>
  );
};
