import Resumable from "resumablejs";
import { defaultResumableOptions } from "../models/file-uploader";

export const uploadFile = async (file, progressCb = (p) => {}) => {
  // get access token
  const { access_token } = JSON.parse(localStorage.getItem("token"));

  // create a new uploader for each module
  const uploader = new Resumable({
    ...defaultResumableOptions,
    headers: {
      Authorization: `Bearer ${access_token}`,
    },
  });

  // upload the image
  uploader.addFile(file);

  // wait for the upload to finish
  try {
    return await new Promise((resolve, reject) => {
      uploader.on("fileAdded", (file) => {
        uploader.upload();
      });

      uploader.on("fileSuccess", (file, event) => {
        try {
          // try to parse the response
          const { result } = JSON.parse(event);
          resolve(result?.original);
        } catch (err) {
          reject(err);
        }
      });

      uploader.on("progress", () => {
        progressCb(uploader.progress() * 100);
      });

      uploader.on("error", (err) => {
        reject(err);
      });
    });
  } catch (err) {
    console.error(`Error when uploading file ${file.name}`, err);
    return null;
  }
};
