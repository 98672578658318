import { Create } from "react-admin";
import ExperienceForm from "./Form/ExperienceForm";
import useExperienceTransform from "../../hooks/useExperienceTransform";
import { LinearProgress } from "@mui/material";

const ExperienceCreate = () => {
  const { transform, progress } = useExperienceTransform();

  return (
    <Create transform={transform}>
      <ExperienceForm />
      {progress && <LinearProgress variant='determinate' value={progress} />}
    </Create>
  );
};

export default ExperienceCreate;
