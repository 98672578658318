import { Create } from "react-admin";
import OrganizationForm from "./OrganizationForm";

const OrganizationCreate = (props) => {
  return (
    <Create>
      <OrganizationForm {...props} />
    </Create>
  );
};

export default OrganizationCreate;
