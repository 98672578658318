import {
  DateInput,
  Loading,
  SimpleForm,
  TextInput,
  usePermissions,
  useRecordContext,
  useRedirect,
} from "react-admin";
import { hasAccess } from "../../global/permissions";
import { Button, Divider, colors } from "@mui/material";
import { Link } from "react-router-dom";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import LicenseListButton from "../Licenses/Buttons/LicenseListButton";
import UserListButton from "../Users/Buttons/UserListButton";
import moment from "moment";

const OrganizationForm = (props) => {
  const redirect = useRedirect();
  const { permissions } = usePermissions(props);
  const record = useRecordContext(props);

  /**
   * Validate data.
   * @param values
   */
  const validateForm = (values) => {
    const errors = {};

    if (!values.name) {
      errors["name"] = "Ce champ est obligatoire";
    }

    if (!values.number_of_workstations) {
      errors["number_of_workstations"] = "Ce champ est obligatoire";
    }

    if (!values.contract_started_at) {
      errors["contract_started_at"] = "Ce champ est obligatoire";
    }

    if (!values.contract_ended_at) {
      errors["contract_ended_at"] = "Ce champ est obligatoire";
    }

    return errors;
  };

  if (!permissions) return <Loading />;

  if (!hasAccess({ name: "organizations", crudType: "edit" }, permissions))
    redirect("/");

  return (
    <SimpleForm validate={validateForm}>
      <TextInput source='name' label='Titre' />
      <TextInput
        source='number_of_workstations'
        label='Nombre de postes'
        defaultValue={1}
        sx={{ display: "none" }}
      />
      <DateInput
        source='contract_started_at'
        label='Date de début de contrat'
        defaultValue={moment().format("YYYY-MM-DD")}
        sx={{ display: "none" }}
      />
      <DateInput
        source='contract_ended_at'
        label='Date de fin de contrat'
        defaultValue={moment().format("YYYY-MM-DD")}
        sx={{ display: "none" }}
      />

      {record !== undefined && (
        <>
          <Divider />
          <LicenseListButton />
          <UserListButton />
        </>
      )}
    </SimpleForm>
  );
};

export default OrganizationForm;
