import { Divider } from "@mui/material";

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles({
  chip: {
    marginRight: ".7em",
  },
  divider: {
    margin: "1em 0em",
    background: "#ffffff14",
  },
});

export const CustomDivider = () => {
  const classes = useStyles();
  return <Divider className={classes.divider} />;
};
