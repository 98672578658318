import React from "react";
import { Link } from "react-router-dom";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import { Button } from "@mui/material";
import { useRecordContext } from "react-admin";

interface LicenseListButtonProps {
  parentResource?: "organizations" | "experiences";
}

const LicenseListButton: React.FC<LicenseListButtonProps> = ({
  parentResource = "organizations",
}) => {
  const record = useRecordContext();

  return (
    <Button
      component={Link}
      to={`/${parentResource}/${record.id}/licenses`}
      variant='text'
      color='primary'
      startIcon={<AccountBalanceWalletIcon />}
    >
      Licences
    </Button>
  );
};

export default LicenseListButton;
