import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import AppsIcon from "@mui/icons-material/Apps";
import BusinessIcon from "@mui/icons-material/Business";
import GroupIcon from "@mui/icons-material/Group";
import PersonIcon from "@mui/icons-material/Person";
import DashboardIcon from "@mui/icons-material/Dashboard";
import LabelIcon from '@mui/icons-material/Label';

export type ResourceType =
  | ""
  | "experiences"
  | "licenses"
  | "organizations"
  | "users"
  | "home/updates"
  | "profile"
  | "tags";

export type CrudType = "create" | "show" | "edit" | "delete" | "list";

export interface IResource {
  name: ResourceType;
  crudType?: CrudType;
}

export const resources: {
  name: ResourceType;
  displayName: string;
  icon: any;
}[] = [
  { name: "", displayName: "Tableau de Bord", icon: <DashboardIcon /> },
  { name: "experiences", displayName: "Expériences", icon: <AppsIcon /> },
  // {
  //   name: "licenses",
  //   displayName: "Licences",
  //   icon: <AccountBalanceWalletIcon />,
  // },
  {
    name: "organizations",
    displayName: "Organisations",
    icon: <BusinessIcon />,
  },
  // { name: "users", displayName: "Utilisateurs", icon: <GroupIcon /> },
  {
    name: "tags",
    displayName: "Tags",
    icon: <LabelIcon />,
  },
  { name: "profile", displayName: "Profil", icon: <PersonIcon /> },
];
