import { useEffect, useState } from "react";
import {
  ArrayField,
  CreateButton,
  Datagrid,
  EmailField,
  List,
  Loading,
  SingleFieldList,
  TextField,
  TopToolbar,
  usePermissions,
  useRecordContext,
  useRedirect,
} from "react-admin";
import dataProvider from "../../data-providers/data-provider";
import { hasAccess } from "../../global/permissions";
import { IRole } from "../../models/role";
import { RoleChipField } from "../Layout/Fields/ChipFields";
import { useParams } from "react-router-dom";
import { Button } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { Link } from "react-router-dom";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

const EditUserButton = () => {
  const user = useRecordContext();
  return (
    <Button
      variant='contained'
      component={Link}
      size='small'
      to={`/organizations/${user?.organization_id}/users/${user?.id}`}
      startIcon={<EditIcon />}
    >
      Editer
    </Button>
  );
};

const UserListActions = () => {
  const { id: organization_id } = useParams();
  return (
    <TopToolbar
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%",
      }}
    >
      <Button
        variant='text'
        size='small'
        component={Link}
        to={`/organizations/${organization_id}/licenses`}
        color="secondary"
        startIcon={<ChevronRightIcon />}
      >
        Licences
      </Button>
      <CreateButton to={`/organizations/${organization_id}/users/create`} />
    </TopToolbar>
  );
};

const UserList = (props) => {
  const [roles, setRoles] = useState<IRole[]>([]);
  const userOrga = JSON.parse(localStorage.getItem("organization"));
  const redirect = useRedirect();
  const { permissions } = usePermissions();
  const { id: organization_id } = useParams();

  useEffect(() => {
    // roles
    if (roles.length === 0)
      dataProvider
        .getList("roles")
        .then(({ data: roles }) => setRoles(roles))
        .catch((err) => console.error(err));
  }, [roles.length]);

  if (roles.length === 0 || !userOrga || !permissions) return <Loading />;

  if (!hasAccess({ name: "users", crudType: "list" }, permissions))
    redirect("/");

  const filter = { organization_id: organization_id };

  return (
    <List
      hasCreate
      exporter={false}
      perPage={50}
      actions={<UserListActions />}
      filter={filter}
      resource='users'
      emptyWhileLoading
      empty={false}
    >
      <Datagrid bulkActionButtons={false}>
        <TextField source='first_name' label='Prénom' />
        <TextField source='last_name' label='Nom' />
        <EmailField source='email' label='Email' />

        <ArrayField source='roles' label='Rôles' sortable={false}>
          <SingleFieldList>
            <RoleChipField roles={roles} {...props} />
          </SingleFieldList>
        </ArrayField>
        <EditUserButton />
      </Datagrid>
    </List>
  );
};

export default UserList;
