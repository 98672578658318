import makeStyles from '@mui/styles/makeStyles';
import { Loading, MenuItemLink, usePermissions, useRedirect } from "react-admin";
import { hasAccess } from "../../global/permissions";
import { resources } from "../../models/resource";
import { Divider } from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';
import authProvider from '../../data-providers/auth-provider';

const useStyles = makeStyles({
  menuItem: {
    marginTop: "1em",
  },
});

export const CustomMenu = ({ onMenuClick }) => {
  const { permissions } = usePermissions();
  const classes = useStyles();
  const redirect = useRedirect();

  if (!permissions) return <Loading />;

  const logout = async () => {
    await authProvider.logout();
    redirect("/login");
  };

  return (
    <>
      {resources
        .filter((item) =>
          hasAccess({ name: item.name, crudType: "list" }, permissions)
        )
        .map((item) => (
          <MenuItemLink
            className={classes.menuItem}
            key={item.name}
            to={`/${item.name}`}
            primaryText={item.displayName}
            onClick={onMenuClick}
            leftIcon={item.icon}
            sidebarIsOpen
          />
        ))}

        <Divider />

        {/* logout */}
        <MenuItemLink
          className={classes.menuItem}
          key='logout'
          to='/'
          primaryText='Se déconnecter'
          onClick={logout}
          leftIcon={<LogoutIcon />}
          sidebarIsOpen
        />
    </>
  );
};
