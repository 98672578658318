import { CrudType, IResource } from "../models/resource";
import { RoleID, IRole, IAPIRole } from "../models/role";

export const hasAccess = (
  { name, crudType }: IResource,
  permissions: RoleID[]
): boolean => {
  switch (name) {
    case "experiences":
      switch (crudType) {
        case "create":
        case "delete":
        case "list":
        case "show":
        case "edit":
        default:
          return (
            permissions.includes("admin") ||
            permissions.includes("contentCreator")
          );
      }

    case "licenses":
      switch (crudType) {
        case "list":
        case "show":
        case "create":
        case "delete":
        case "edit":
        default:
          return (
            permissions.includes("admin") || permissions.includes("adminOrga")
          );
      }

    case "organizations":
      switch (crudType) {
        case "edit":
        case "list":
        case "show":
          return (
            permissions.includes("admin") || permissions.includes("adminOrga")
          );
        case "create":
        case "delete":
        default:
          return permissions.includes("admin");
      }

    case "users":
      switch (crudType) {
        case "create":
        case "delete":
        case "edit":
        case "list":
        case "show":
        default:
          return (
            permissions.includes("admin") || permissions.includes("adminOrga")
          );
      }

    case "home/updates":
      return permissions.includes("admin");

    case "profile":
      return true;

    case "tags":
      return permissions.includes("admin");

    case "":
      return true;

    default:
      return false;
  }
};

export const formatRolesForEdit = (roles: IAPIRole[]): IRole[] => {
  return roles.map((role) => ({
    id: role.name,
    name: getRoleDisplayName(role.name),
  }));
};

export const formatRolesForList = (roles: IAPIRole[]) => {
  return roles.map((role) => getRoleDisplayName(role.name));
};

const getRoleDisplayName = (roleID: RoleID) => {
  switch (roleID) {
    case "normal":
      return "Normal";
    case "admin":
      return "Administrateur";
    case "adminOrga":
      return "Administrateur d'Organisation";
  }
};
