import { Edit } from "react-admin";
import licenseService from "../../services/license-service";
import LicenseForm from "./LicenseForm";
import { useParams } from "react-router-dom";

interface LicenseEditProps {
  parentResource?: "organizations" | "experiences";
}

const LicenseEdit: React.FC<LicenseEditProps> = ({ parentResource = "organizations" }) => {
  const { id: parentResourceId, licenseId } = useParams();

  return (
    <Edit
      transform={licenseService.transformLicense}
      resource='licenses'
      id={licenseId}
      redirect={`/${parentResource}/${parentResourceId}/licenses`}
    >
      <LicenseForm mode='edit' parentResource={parentResource} />
    </Edit>
  );
};

export default LicenseEdit;
