import { Admin, CustomRoutes, Resource } from "react-admin";
import frenchMessages from "ra-language-french";
import polyglotI18nProvider from "ra-i18n-polyglot";
import authProvider from "../../data-providers/auth-provider";
import dataProvider from "../../data-providers/data-provider";
import { theme } from "../../global/styles";
import ExperienceCreate from "../Experiences/ExperienceCreate";
import ExperienceEdit from "../Experiences/ExperienceEdit";
import ExperienceList from "../Experiences/ExperienceList";
import CustomLayout from "../Layout/CustomLayout";
import LicenseCreate from "../Licenses/LicenseCreate";
import LicenseEdit from "../Licenses/LicenseEdit";
import LicenseList from "../Licenses/LicenseList";
import CustomLogin from "../Login/CustomLogin";
import NotFound from "../NotFound/NotFound";
import OrganizationCreate from "../Organizations/OrganizationCreate";
import OrganizationEdit from "../Organizations/OrganizationEdit";
import OrganizationList from "../Organizations/OrganizationList";
import UserCreate from "../Users/UserCreate";
import UserEdit from "../Users/UserEdit";
import UserList from "../Users/UserList";
import { hasAccess } from "../../global/permissions";
import { RoleID } from "../../models/role";
import Dashboard from "../Dashboard/Dashboard";
import TagList from "../Tags/TagList";
import TagCreate from "../Tags/TagCreate";
import TagEdit from "../Tags/TagEdit";
import { Route } from "react-router-dom";
import { Profile } from "../Profile/Profile";
import { useClearCacheCtx } from "react-clear-cache";
import { useEffect } from "react";

const messages = {
  fr: frenchMessages,
};
const i18nProvider = polyglotI18nProvider(() => messages["fr"]);

window.addEventListener("beforeunload", (ev) => {
  ev.preventDefault();

  if (localStorage.getItem("rememberMe")) {
    const rememberMe = JSON.parse(localStorage.getItem("rememberMe"));
    if (!rememberMe) {
      localStorage.clear();
    }
  }
});

const App = () => {
  const { isLatestVersion, emptyCacheStorage } = useClearCacheCtx();

  useEffect(() => {
    if (isLatestVersion) return;
    console.log("New version available. Clearing cache...");
    emptyCacheStorage();
  }, [isLatestVersion, emptyCacheStorage]);

  return (
    <Admin
      title='UniVR Admin'
      dataProvider={dataProvider}
      authProvider={authProvider}
      loginPage={CustomLogin}
      catchAll={NotFound}
      theme={theme}
      layout={CustomLayout}
      i18nProvider={i18nProvider}
      dashboard={Dashboard}
    >
      {(permissions: RoleID[]) => (
        <>
          <Resource
            name='experiences'
            options={{ label: "Expériences" }}
            recordRepresentation='name'
            list={
              hasAccess({ name: "experiences", crudType: "list" }, permissions)
                ? ExperienceList
                : null
            }
            create={
              hasAccess(
                { name: "experiences", crudType: "create" },
                permissions
              )
                ? ExperienceCreate
                : null
            }
            edit={
              hasAccess({ name: "experiences", crudType: "edit" }, permissions)
                ? ExperienceEdit
                : null
            }
          >
            {/* License list route */}
            <Route
              path='/:id/licenses'
              element={<LicenseList parentResource='experiences' />}
            />
            {/* License create route */}
            <Route
              path='/:id/licenses/create'
              element={<LicenseCreate parentResource='experiences' />}
            />
            {/* License edit route */}
            <Route
              path='/:id/licenses/:licenseId'
              element={<LicenseEdit parentResource='experiences' />}
            />
          </Resource>

          <Resource
            name='organizations'
            options={{ label: "Organisations" }}
            recordRepresentation='name'
            list={
              hasAccess(
                { name: "organizations", crudType: "list" },
                permissions
              )
                ? OrganizationList
                : null
            }
            create={
              hasAccess(
                { name: "organizations", crudType: "create" },
                permissions
              )
                ? OrganizationCreate
                : null
            }
            edit={
              hasAccess(
                { name: "organizations", crudType: "edit" },
                permissions
              )
                ? OrganizationEdit
                : null
            }
          >
            {/* License list route */}
            <Route
              path='/:id/licenses'
              element={<LicenseList parentResource='organizations' />}
            />
            {/* License create route */}
            <Route
              path='/:id/licenses/create'
              element={<LicenseCreate parentResource='organizations' />}
            />
            {/* License edit route */}
            <Route
              path='/:id/licenses/:licenseId'
              element={<LicenseEdit parentResource='organizations' />}
            />

            {/* User list route */}
            <Route path='/:id/users' element={<UserList />} />
            {/* User create route */}
            <Route path='/:id/users/create' element={<UserCreate />} />
            {/* User edit route */}
            <Route path='/:id/users/:userId' element={<UserEdit />} />
          </Resource>

          <Resource
            name='users'
            recordRepresentation='initials'
            options={{ label: "Utilisateurs" }}
          />

          <Resource name='licenses' options={{ label: "Licences" }} />

          <Resource
            name='tags'
            recordRepresentation='name'
            list={
              hasAccess({ name: "tags", crudType: "list" }, permissions)
                ? TagList
                : null
            }
            create={
              hasAccess({ name: "tags", crudType: "create" }, permissions)
                ? TagCreate
                : null
            }
            edit={
              hasAccess({ name: "tags", crudType: "edit" }, permissions)
                ? TagEdit
                : null
            }
          />
        </>
      )}

      <CustomRoutes>
        <Route path='/profile' element={<Profile />} />
      </CustomRoutes>
    </Admin>
  );
};

export default App;
