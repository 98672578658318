import { useEffect, useState } from "react";
import { SimpleForm, TextInput, SelectInput, Loading } from "react-admin";
import dataProvider from "../../data-providers/data-provider";

const TagForm = () => {
  const [types, setTypes] = useState<{ id: string; name: string }[]>([]);

  useEffect(() => {
    dataProvider.getList("tags/types").then((response) => {
      setTypes(response.data);
    });
  }, []);

  /**
   * Validate data.
   * @param values
   */
  const validateForm = (values) => {
    const errors = {};

    if (!values.name) {
      errors["name"] = "Ce champ est obligatoire";
    }

    if (!values.type) {
      errors["type"] = "Ce champ est obligatoire";
    }

    return errors;
  };

  if (types.length === 0) return <Loading />;

  return (
    <SimpleForm validate={validateForm}>
      <TextInput source='name' label='Nom' />

      <SelectInput source='type' choices={types} label='Type' />
    </SimpleForm>
  );
};

export default TagForm;
