import { Edit } from "react-admin";
import ExperienceForm from "./Form/ExperienceForm";
import { LinearProgress } from "@mui/material";
import useExperienceTransform from "../../hooks/useExperienceTransform";

const ExperienceEdit = () => {
  const { transform, progress } = useExperienceTransform();

  return (
    <Edit transform={transform} >
      <ExperienceForm />
      {progress && <LinearProgress variant='determinate' value={progress} />}
    </Edit>
  );
};

export default ExperienceEdit;
