import { Grid } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { useEffect, useState } from "react";
import { Loading } from "react-admin";
import dataProvider from "../../data-providers/data-provider";
import { hasAccess } from "../../global/permissions";
import { IRole } from "../../models/role";
import { DashboardCard } from "../Layout/DashboardCard";

const useStyles = makeStyles({
    grid: {
      marginTop: "1em",
    },
  });


export const DashboardLastNews = ({ permissions, ...props }) => {
const classes = useStyles();
const { id: organizationId } = JSON.parse(
    localStorage.getItem("organization")
);
const [roles, setRoles] = useState<IRole[]>([]);

useEffect(() => {
    // roles
    if (roles.length === 0)
      dataProvider
        .getList("roles")
        .then(({ data: roles }) => setRoles(roles))
        .catch((err) => console.error(err));
  }, [roles.length]);

  if (!organizationId || !permissions || roles.length === 0) return <Loading />;

  return(
    <>
  <Grid  container spacing={4} className={classes.grid}>
          
    {hasAccess({ name: "experiences", crudType: "list" }, permissions) &&(
      <Grid item xs={6}>
        <DashboardCard
          resource='experiences'
          organizationId={
            !permissions.includes("admin") ? organizationId : null
          }
        />
      </Grid>
    )}

    {hasAccess(
      { name: "organizations", crudType: "list" },
      permissions
    ) && (
      <Grid item xs={6}>
        <DashboardCard
          resource='organizations'
          organizationId={
            !permissions.includes("admin") ? organizationId : null
          }
        />
      </Grid>
    )}

    {hasAccess({ name: "users", crudType: "list" }, permissions) && (
      <Grid item xs={6}>
        <DashboardCard
          resource='users'
          organizationId={
            !permissions.includes("admin") ? organizationId : null
          }
          roles={roles}
        />
      </Grid>
    )}

    {hasAccess({ name: "licenses", crudType: "list" }, permissions) && (
      <Grid item xs={6}>
        <DashboardCard
          resource='licenses'
          organizationId={
            !permissions.includes("admin") ? organizationId : null
          }
        />
      </Grid>
    )}
  </Grid>
  </>
  )
}