import {
  Datagrid,
  EditButton,
  Filter,
  List,
  Loading,
  TextField,
  TextInput,
  usePermissions,
  useRedirect,
} from "react-admin";
import { hasAccess } from "../../global/permissions";
import { CustomPagination } from "../Layout/CustomPagination";
import LicenseListButton from "../Licenses/Buttons/LicenseListButton";
import UserListButton from "../Users/Buttons/UserListButton";

const OrganizationFilter = ({ permissions, ...props }) => (
  <Filter {...props}>
    <TextInput
      label='Nom'
      variant='outlined'
      placeholder='Nom'
      source='name'
      alwaysOn
      disabled={!permissions.includes("admin")}
    />
  </Filter>
);

const OrganizationList = () => {
  const organization = JSON.parse(localStorage.getItem("organization"));
  const redirect = useRedirect();
  const { permissions } = usePermissions();

  if (!organization || !permissions) return <Loading />;

  if (!hasAccess({ name: "organizations", crudType: "list" }, permissions))
    redirect("/");

  return (
    <List
      hasCreate={hasAccess(
        { name: "organizations", crudType: "create" },
        permissions
      )}
      exporter={false}
      perPage={50}
      pagination={<CustomPagination />}
      filters={<OrganizationFilter permissions={permissions} />}
      filterDefaultValues={
        !permissions.includes("admin") ? { name: organization.name } : null
      }
      sort={{ field: "updated_at", order: "DESC" }}
    >
      <Datagrid bulkActionButtons={false}>
        <TextField source='name' label='Nom' />
        <LicenseListButton />
        <UserListButton />
        <EditButton variant='contained' label='Editer' />
      </Datagrid>
    </List>
  );
};

export default OrganizationList;
