import {
  DateInput,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  FormDataConsumer,
  useRedirect,
  Loading,
  AutocompleteInput,
  AutocompleteArrayInput,
  ReferenceArrayInput,
  useRecordContext,
  usePermissions,
  NumberInput,
} from "react-admin";
import { hasAccess } from "../../global/permissions";
import { Divider, FormControlLabel, Stack, Switch } from "@mui/material";
import { useState } from "react";
import { useParams } from "react-router-dom";

interface LicenseFormProps {
  mode?: "create" | "edit";
  parentResource?: "organizations" | "experiences";
}

const LicenseForm: React.FC<LicenseFormProps> = ({
  mode = "create",
  parentResource = "organizations",
}) => {
  const redirect = useRedirect();
  const { id: parentResourceId } = useParams();
  const { id: userOrgaId } = JSON.parse(localStorage.getItem("organization")); // User organization id
  const { permissions } = usePermissions();
  const record = useRecordContext();
  const [displaySelect, setDisplaySelect] = useState(
    record?.user_ids?.length > 0 ?? false
  );

  const NameField = () => {
    const record = useRecordContext();
    return <span>{record.name}</span>;
  };

  /**
   * Validate data.
   * @param values
   */
  const validateForm = (values) => {
    const errors = {};

    if (!values.started_at) {
      errors["started_at"] = "Ce champ est obligatoire";
    }

    if (!values.type) {
      errors["type"] = "Ce champ est obligatoire";
    }

    // if (!values.organization_id) {
    //   errors["organization_id"] = "Ce champ est obligatoire";
    // }

    // if (!values.experience_id) {
    //   errors["experience_id"] = "Ce champ est obligatoire";
    // }

    return errors;
  };

  if (!permissions || !userOrgaId) return <Loading />;

  if (!hasAccess({ name: "licenses", crudType: "edit" }, permissions))
    redirect("/");

  return (
    <SimpleForm validate={validateForm}>
      {/* Hidden field */}
      <NumberInput
        source={
          parentResource === "experiences"
            ? "experience_id"
            : parentResource === "organizations"
            ? "organization_id"
            : null
        }
        defaultValue={parentResourceId}
        required
        sx={{ display: "none" }}
      />

      <DateInput
        source='started_at'
        label='Date de début'
        defaultValue={Date.now()}
        required
      />

      <SelectInput
        source='type'
        defaultValue='6 months'
        label='Durée'
        choices={[
          { id: "6 months", name: "6 Mois" },
          { id: "1 year", name: "1 Année" },
          { id: "unlimited", name: "Illimité" },
          { id: "custom", name: "Personnalisé" },
        ]}
        required
      />

      {/* add organization reference input */}
      {parentResource === "experiences" && (
        <ReferenceInput
          source='organization_id'
          reference='organizations'
          perPage={9999}
          sort={{ field: "name", order: "ASC" }}
        >
          <AutocompleteInput
            label='Organisation'
            optionText={<NameField />}
            inputText={(option) => option.name}
            matchSuggestion={(filterValue, suggestion) =>
              suggestion.name.toLowerCase().includes(filterValue.toLowerCase())
            }
            variant='outlined'
            sx={{ width: "100%", maxWidth: "24em" }}
          />
        </ReferenceInput>
      )}

      {parentResource === "organizations" && (
        <FormDataConsumer>
          {({ formData, ...rest }) =>
            formData.type === "custom" && (
              <DateInput
                {...rest}
                source='ended_at'
                label='Date de fin'
                required
              />
            )
          }
        </FormDataConsumer>
      )}

      {parentResource === "organizations" && (
        <FormDataConsumer>
          {({ formData, scopedFormData, ...rest }) => (
            <Stack maxWidth='24em' width='100%'>
              {!formData.tag_id && (
                <ReferenceInput
                  label='Expérience'
                  source='experience_id'
                  reference='experiences'
                  perPage={9999}
                  filter={
                    !permissions.includes("admin")
                      ? { organization_id: userOrgaId }
                      : null
                  }
                  sort={{ field: "name", order: "ASC" }}
                >
                  <AutocompleteInput
                    optionText={<NameField />}
                    inputText={(option) => option.name}
                    matchSuggestion={(filterValue, suggestion) =>
                      suggestion.name
                        .toLowerCase()
                        .includes(filterValue.toLowerCase())
                    }
                    variant='outlined'
                    sx={{ width: "100%", maxWidth: "24em" }}
                  />
                </ReferenceInput>
              )}

              {!formData.tag_id &&
                !formData.experience_id &&
                mode === "create" && <Divider flexItem>OU</Divider>}

              {!formData.experience_id && mode === "create" && (
                <ReferenceInput
                  source='tag_id'
                  reference='tags'
                  perPage={9999}
                  filter={{
                    type: "tag",
                  }}
                >
                  <AutocompleteInput
                    label="Groupe d'expériences"
                    inputText={(option) => option.name}
                    matchSuggestion={(filterValue, suggestion) =>
                      suggestion.name
                        .toLowerCase()
                        .includes(filterValue.toLowerCase())
                    }
                    variant='outlined'
                    sx={{ width: "100%", maxWidth: "24em" }}
                  />
                </ReferenceInput>
              )}
            </Stack>
          )}
        </FormDataConsumer>
      )}

      <FormControlLabel
        control={
          <Switch
            onChange={(e) => setDisplaySelect(e.target.checked)}
            value={displaySelect}
            defaultChecked={displaySelect}
            color='primary'
          />
        }
        label='Utilisateurs spécifiques ?'
      />

      {displaySelect && (
        <FormDataConsumer>
          {({ formData, scopedFormData, ...rest }) => (
            <ReferenceArrayInput
              source='user_ids'
              reference='users'
              perPage={9999}
              filter={{ organization_id: formData.organization_id }}
              sort={{ field: "first_name", order: "ASC" }}
            >
              <AutocompleteArrayInput
                optionText={(user) =>
                  user ? `${user?.first_name} ${user?.last_name}` : "-"
                }
                sx={{ width: "100%", maxWidth: "24em" }}
                label='Utilisateurs'
              />
            </ReferenceArrayInput>
          )}
        </FormDataConsumer>
      )}
    </SimpleForm>
  );
};

export default LicenseForm;
