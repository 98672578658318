import {
  CreateButton,
  Datagrid,
  DateField,
  FunctionField,
  List,
  Loading,
  ReferenceArrayField,
  ReferenceField,
  TextField,
  TopToolbar,
  usePermissions,
  useRecordContext,
  useRedirect,
} from "react-admin";
import { hasAccess } from "../../global/permissions";
import licenseService from "../../services/license-service";
import { useParams } from "react-router-dom";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

interface EditLicenseButtonProps extends LicenseListProps {}

const EditLicenseButton: React.FC<EditLicenseButtonProps> = ({ parentResource }) => {
  const license = useRecordContext();
  const { id: parentResourceId } = useParams();
  
  return (
    <Button
      variant='contained'
      component={Link}
      size='small'
      to={`/${parentResource}/${parentResourceId}/licenses/${license?.id}`}
      startIcon={<EditIcon />}
    >
      Editer
    </Button>
  );
};

interface LicenseListActionsProps extends LicenseListProps {}

const LicenseListActions: React.FC<LicenseListActionsProps> = ({
  parentResource,
}) => {
  const { id: organization_id } = useParams();
  return (
    <TopToolbar
      sx={{
        display: "flex",
        alignItems: "center",
        width: "100%",
        justifyContent: parentResource === "organizations" ? "space-between" : "",
      }}
    >
      {parentResource === "organizations" && (
        <Button
          variant='text'
          size='small'
          component={Link}
          to={`/organizations/${organization_id}/users`}
          color='secondary'
          startIcon={<ChevronRightIcon />}
        >
          Utilisateurs
        </Button>
      )}
      <CreateButton to={`/${parentResource}/${organization_id}/licenses/create`} />
    </TopToolbar>
  );
};

interface LicenseListProps {
  parentResource?: "organizations" | "experiences";
}

const LicenseList: React.FC<LicenseListProps> = ({ parentResource = "organizations" }) => {
  const organization = JSON.parse(localStorage.getItem("organization"));
  const redirect = useRedirect();
  const { permissions } = usePermissions();
  const { id: parentResourceId } = useParams();

  if (!organization || !permissions) return <Loading />;

  if (!hasAccess({ name: "licenses", crudType: "list" }, permissions))
    redirect("/");

  const filter =
    parentResource === "organizations"
      ? { organization_id: parentResourceId }
      : parentResource === "experiences"
      ? { experience_id: parentResourceId }
      : {};

  return (
    <List
      resource='licenses'
      hasCreate={hasAccess(
        { name: "licenses", crudType: "create" },
        permissions
      )}
      filter={filter}
      sort={{ field: "updated_at", order: "DESC" }}
      actions={<LicenseListActions parentResource={parentResource} />}
      emptyWhileLoading
      empty={false}
    >
      <Datagrid bulkActionButtons={false}>
        <DateField source='started_at' label='Date de début' />
        <FunctionField
          label='Date de fin'
          render={licenseService.renderEndDate}
          sortBy='ended_at'
        />
        {parentResource === "organizations" && (
          <ReferenceField
            label='Experience'
            source='experience_id'
            reference='experiences'
          >
            <TextField source='name' />
          </ReferenceField>
        )}
        {parentResource === "experiences" && (
          <ReferenceField
            label='Organisation'
            source='organization_id'
            reference='organizations'
          >
            <TextField source='name' />
          </ReferenceField>
        )}
        <ReferenceArrayField
          label='Utilisateurs'
          reference='users'
          source='user_ids'
          fullWidth
        ></ReferenceArrayField>
        {hasAccess({ name: "licenses", crudType: "edit" }, permissions) && (
          <EditLicenseButton parentResource={parentResource} />
        )}
      </Datagrid>
    </List>
  );
};

export default LicenseList;
