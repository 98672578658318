import {
  Datagrid,
  DateField,
  EditButton,
  Filter,
  List,
  Loading,
  TextField,
  TextInput,
  usePermissions,
  useRedirect,
} from "react-admin";
import { hasAccess } from "../../global/permissions";
import { CustomPagination } from "../Layout/CustomPagination";
import { CustomTitle } from "../Layout/CustomTitle";

const TagFilter = ({ permissions, ...props }) => (
  <Filter {...props}>
    <TextInput
      label='Type'
      variant='outlined'
      placeholder='Type'
      source='type'
      alwaysOn
    />
    <TextInput label='Nom' variant='outlined' placeholder='Nom' source='name' />
  </Filter>
);

const TagList = (props) => {
  const organization = JSON.parse(localStorage.getItem("organization"));
  const redirect = useRedirect();
  const { permissions } = usePermissions();

  if (!organization || !permissions) return <Loading />;

  if (!hasAccess({ name: "tags", crudType: "list" }, permissions))
    redirect("/");

  return (
    <List
      hasCreate
      exporter={false}
      perPage={50}
      pagination={<CustomPagination />}
      filters={<TagFilter permissions={permissions} />}
      filterDefaultValues={
        !permissions.includes("admin")
          ? { organization: organization.name }
          : null
      }
    >
      <Datagrid rowClick='edit' bulkActionButtons={false}>
        <TextField source='name' label='Nom' />
        <TextField source='type_display_name' label='Type' />
        <DateField source='updated_at' label='Dernière mise à jour' showTime />
        <EditButton variant='contained' label='Editer' />
      </Datagrid>
    </List>
  );
};

export default TagList;
