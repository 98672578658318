import React from "react";
import { Link } from "react-router-dom";
import GroupIcon from "@mui/icons-material/Group";
import { Button } from "@mui/material";
import { useRecordContext } from "react-admin";

const UserListButton = () => {
  const record = useRecordContext();

  return (
    <Button
      component={Link}
      to={`/organizations/${record.id}/users`}
      variant='text'
      color='primary'
      startIcon={<GroupIcon />}
    >
      Utilisateurs
    </Button>
  );
};

export default UserListButton;
