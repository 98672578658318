import {
  ListItem,
  ListSubheader,
  Typography,
  List,
  Button,
  IconButton,
  TextField,
  MenuItem,
  Select,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Loading, Title, useNotify, usePermissions } from "react-admin";
import { CustomTitle } from "../Layout/CustomTitle";
import EditIcon from "@mui/icons-material/Edit";
import { CustomDivider } from "../Layout/CustomDivider";
import { useState, useEffect } from "react";
import dataProvider from "../../data-providers/data-provider";
import { APIURL } from "../../global/global";
import SaveIcon from "@mui/icons-material/Save";

const useStyles = makeStyles({
  itemTitle: {
    color: "#919191",
    width: "10em",
  },
  itemValue: {
    flex: "1",
  },
  chip: {
    marginRight: "1em",
  },
  save: {
    width: "fit-content",
    marginTop: "4em",
  },
  list: {
    marginTop: "1em",
  },
});

interface ItemProps {
  value: string;
  title: string;
  onSave: (any) => void;
}

interface SelectItemProps extends ItemProps {
  choices: { id: number; name: string }[];
}

const TextItem = ({ title, value, onSave }: ItemProps) => {
  const classes = useStyles();
  const [edit, setEdit] = useState(false);
  const [state, setState] = useState<string>(value);

  const onChange = (event) => {
    setState(event.target.value);
    onSave({ value: event.target.value });
  };

  return (
    <ListItem>
      <Typography className={classes.itemTitle}>{title}</Typography>

      {/* Field */}
      {!edit && <Typography className={classes.itemValue}>{state}</Typography>}

      {/* Input */}
      {edit && (
        <TextField
          className={classes.itemValue}
          //   label={value}
          defaultValue={value}
          variant='outlined'
          onChange={onChange}
        />
      )}

      <IconButton aria-label='edit' onClick={() => setEdit(!edit)} size='large'>
        <EditIcon />
      </IconButton>
    </ListItem>
  );
};

const SelectItem = ({ title, value, choices, onSave }: SelectItemProps) => {
  const classes = useStyles();
  const [edit, setEdit] = useState(false);
  const [state, setState] = useState<any>(value);

  const onChange = (event) => {
    setState(event.target.value);
    onSave({ value: event.target.value });
  };

  return (
    <ListItem>
      <Typography className={classes.itemTitle}>{title}</Typography>

      {/* Field */}
      {!edit && (
        <Typography className={classes.itemValue}>
          {choices.find((choice) => choice.id === state)?.name}
        </Typography>
      )}

      {/* Input */}
      {edit && (
        <Select
          className={classes.itemValue}
          value={state}
          defaultValue={state}
          onChange={onChange}
        >
          {choices.map((choice) => (
            <MenuItem value={choice.id}>{choice.name}</MenuItem>
          ))}
        </Select>
      )}

      <IconButton aria-label='edit' onClick={() => setEdit(!edit)} size='large'>
        <EditIcon />
      </IconButton>
    </ListItem>
  );
};

export const Profile = () => {
  /**
   * LocalStorage
   */
  const {
    id: userId,
    first_name,
    last_name,
    email,
  } = JSON.parse(localStorage.getItem("me"));
  const organization = JSON.parse(localStorage.getItem("organization"));

  /**
   * Hooks => use localStorage data as fuel for userState
   */
  const classes = useStyles();
  const notify = useNotify();
  const { permissions } = usePermissions();
  const [userState, setUserState] = useState({
    id: userId,
    first_name,
    last_name,
    organization_id: organization.id,
    email,
    // roles: [],
  });
  const [organizations, setOrganizations] = useState<
    { id: number; name: string }[]
  >([]);

  // get data from API
  useEffect(() => {
    // get roles
    // if (userState.roles.length === 0)
    //   dataProvider
    //     .getList("roles")
    //     .then(({ data: roles }) => {
    //       const roleUserIds = JSON.parse(localStorage.getItem("roles"));
    //       setUserState({
    //         ...userState,
    //         roles: roleUserIds.map((id) =>
    //           roles.find((role) => role.id === id)
    //         ),
    //       });
    //     })
    //     .catch((err) => console.error(err));

    // get organizations
    if (organizations.length === 0 && permissions)
      dataProvider
        .getList("organizations")
        .then(({ data }) => {
          let result = data.map((o) => ({ id: o.id, name: o.name }));

          if (!permissions.includes("admin"))
            result = data.filter((o) => o.id === organization.id);

          setOrganizations(result);
        })
        .catch((err) => console.error(err));
  }, [organization.id, organizations.length, permissions, userState]);

  const onSave = () => {
    const { access_token } = JSON.parse(localStorage.getItem("token"));

    const request = new Request(`${APIURL}/users/${userId}`, {
      method: "POST",
      body: JSON.stringify(userState),
      headers: new Headers({
        "Content-Type": "application/json",
        locale: "fr",
        Authorization: `Bearer ${access_token}`,
      }),
    });

    fetch(request)
      .then((res) => res.json())
      .then(
        ({ id, email, first_name, last_name, organization_id, roles }) => {
          localStorage.setItem("roles", JSON.stringify(roles));
          localStorage.setItem(
            "me",
            JSON.stringify({ id, email, first_name, last_name })
          );

          dataProvider
            .getOne("organizations", { id: organization_id })
            .then(({ data }) => {
              localStorage.setItem(
                "organization",
                JSON.stringify({ id: data.id, name: data.name })
              );

              notify("Profil mis à jour avec succès", { type: "info" });
            });
        },

        (error) => notify("ra.notification.http_error", { type: "error" })
      );
  };

  if (
    // userState.roles.length === 0 ||
    organizations.length === 0 ||
    !permissions
  )
    return <Loading />;

  return (
    <>
      <Title title={<CustomTitle titles={["Profil"]} />} />

      <List
        className={classes.list}
        subheader={<ListSubheader>Informations utilisateur</ListSubheader>}
      >
        <TextItem
          title='Prénom'
          value={userState.first_name}
          onSave={({ value }) =>
            setUserState({ ...userState, first_name: value })
          }
        />
        <CustomDivider />

        <TextItem
          title='Nom'
          value={userState.last_name}
          onSave={({ value }) =>
            setUserState({ ...userState, last_name: value })
          }
        />
        <CustomDivider />

        <TextItem
          title='Email'
          value={userState.email}
          onSave={({ value }) => setUserState({ ...userState, email: value })}
        />
        <CustomDivider />

        <SelectItem
          title='Organisation'
          value={userState.organization_id}
          choices={organizations}
          onSave={({ value }) =>
            setUserState({ ...userState, organization_id: value })
          }
        />

        {/* <CustomListItem title='Rôles' value={roles} />  */}
      </List>

      <Button
        className={classes.save}
        variant='contained'
        color='primary'
        onClick={onSave}
        startIcon={<SaveIcon />}
      >
        Enregistrer
      </Button>
    </>
  );
};
