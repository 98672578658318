import { Create } from "react-admin";
import { CustomTitle } from "../Layout/CustomTitle";
import TagForm from "./TagForm";

const TagCreate = (props) => {
  return (
    <Create>
      <TagForm {...props} />
    </Create>
  );
};

export default TagCreate;
