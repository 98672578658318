import { RichTextInput } from "ra-input-rich-text";
import {
  BooleanInput,
  DeleteButton,
  DeleteWithConfirmButton,
  FormDataConsumer,
  FormTab,
  Loading,
  SaveButton,
  SelectInput,
  TabbedForm,
  TabbedFormTabs,
  TextInput,
  Toolbar,
  required,
  usePermissions,
  useRecordContext,
  useRedirect,
} from "react-admin";
import { FilesFormTab } from "./Tabs/Files";
import { VersionFormTab } from "./Tabs/Version";
import { hasAccess } from "../../../global/permissions";
import { TagsFormTab } from "./Tabs/Tags";
import ModulesFormTab from "./Tabs/Modules";

const ExperienceToolbar = () => {
  const record = useRecordContext();
  return (
    <Toolbar
      sx={{
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <SaveButton />
      {record && <DeleteWithConfirmButton size='medium' />}
    </Toolbar>
  );
};

const ExperienceForm = (props) => {
  const redirect = useRedirect();
  const { permissions } = usePermissions();
  const { id: organizationId } = JSON.parse(
    localStorage.getItem("organization")
  );

  /**
   * Validate data.
   * @param values
   */
  const validateForm = (values) => {
    const errors = {};

    // name
    if (!values.name) {
      errors["name"] = "Ce champ est obligatoire";
    }

    // description
    if (!values.description) {
      errors["description"] = "Ce champ est obligatoire";
    }

    return errors;
  };

  if (!permissions) return <Loading />;

  if (!hasAccess({ name: "experiences", crudType: "edit" }, permissions))
    redirect("/");

  return (
    <TabbedForm
      validate={validateForm}
      tabs={
        <TabbedFormTabs
          variant='scrollable'
          scrollButtons='auto'
          style={{ maxWidth: "none" }}
        />
      }
      warnWhenUnsavedChanges={false}
      sanitizeEmptyValues
      toolbar={<ExperienceToolbar />}
    >
      <FormTab label='Général'>
        {/* hidden field with organization id */}
        <TextInput
          source='organization_id'
          defaultValue={organizationId}
          style={{ display: "none" }}
        />

        <TextInput source='name' label='Titre' style={{ width: "25em" }} />

        <TextInput source='slug' label='Slug' style={{ width: "25em" }} />

        <SelectInput
          source='type'
          defaultValue='build'
          choices={[
            { id: "build", name: "Expérience avec build" },
            { id: "browser", name: "Expérience sur navigateur" },
            { id: "path", name: "Expérience avec chemin vers un exécutable" },
            { id: "video", name: "Vidéo 360°" },
          ]}
          style={{ width: "25em" }}
        />

        <FormDataConsumer>
          {({ formData, ...rest }) =>
            formData.type === "path" && (
              <SelectInput
                {...rest}
                label='Dossier'
                source='path_prefix'
                defaultValue='build'
                validate={[required()]}
                emptyText='Chemin absolu'
                choices={[
                  { id: "oculus", name: "Oculus" },
                  { id: "steam", name: "Steam" },
                ]}
                style={{ width: "25em" }}
              />
            )
          }
        </FormDataConsumer>

        <FormDataConsumer>
          {({ formData, ...rest }) =>
            (formData.type === "build" || formData.type === "path") && (
              <TextInput
                {...rest}
                source='path_to_exe'
                label="Chemin vers l'exécutable"
                placeholder='/mon_experience/my_app.exe'
                style={{ width: "25em", height: "3em" }}
              />
            )
          }
        </FormDataConsumer>

        <FormDataConsumer>
          {({ formData, ...rest }) =>
            formData.type === "browser" && (
              <TextInput
                {...rest}
                source='url'
                label='URL'
                placeholder='http://mon_experience.com'
                style={{ width: "25em" }}
              />
            )
          }
        </FormDataConsumer>

        <RichTextInput source='description' label='Description' />
      </FormTab>

      <TagsFormTab {...props} />

      <FormTab label='Options'>
        <BooleanInput
          source='is_public'
          label='Disponible pour tous les utilisateurs (sans licence)'
        />
        <BooleanInput
          source='display_in_discover'
          label='Visible dans la partie "A découvrir"'
        />
      </FormTab>

      <FilesFormTab {...props} />

      <ModulesFormTab {...props} />

      <VersionFormTab {...props} />
    </TabbedForm>
  );
};

export default ExperienceForm;
