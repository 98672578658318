import { useEffect, useState } from "react";
import { Loading, usePermissions } from "react-admin";
import dataProvider from "../../data-providers/data-provider";
import { IRole } from "../../models/role";
import { DashboardLastNews } from "./DashboardLastNews";
import { redirect } from "react-router-dom";
import { Box } from "@mui/material";

const Dashboard = () => {
  const [roles, setRoles] = useState<IRole[]>([]);
  const { permissions } = usePermissions();

  const { id: organizationId } = JSON.parse(
    localStorage.getItem("organization") ?? "{}"
  );

  if (organizationId) redirect("/login");

  useEffect(() => {
    // roles
    if (roles.length === 0)
      dataProvider
        .getList("roles")
        .then(({ data: roles }) => setRoles(roles))
        .catch((err) => console.error(err));
  }, [roles.length]);

  if (!organizationId || !permissions || roles.length === 0) return <Loading />;

  return (
    <Box pb={2} pr={2}>
      <DashboardLastNews permissions={permissions} />
    </Box>
  );
};

export default Dashboard;
