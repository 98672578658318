import { Edit } from "react-admin";
import TagForm from "./TagForm";

const TagEdit = (props) => {
  return (
    <Edit>
      <TagForm {...props} />
    </Edit>
  );
};

export default TagEdit;
