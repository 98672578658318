import { APIURL, HEADERS } from "../global/global";

/* eslint-disable import/no-anonymous-default-export */
export default {
  login: async (loginForm) => {
    try {
      // login
      const request = new Request(`${APIURL}/auth/login`, {
        method: "POST",
        body: JSON.stringify(loginForm),
        headers: new Headers({
          "Content-Type": "application/json",
          locale: "fr",
        }),
      });

      const response = await fetch(request);

      if (response.status < 200 || response.status >= 300) {
        throw new Error(response.statusText);
      }

      const {
        access_token,
        roles,
        user,
        organization,
      } = await response.json();
      localStorage.setItem(
        "token",
        JSON.stringify({ access_token })
      );
      localStorage.setItem("roles", JSON.stringify(roles));
      localStorage.setItem("me", JSON.stringify(user));
      localStorage.setItem("organization", JSON.stringify(organization));
    } catch (e) {
      console.log(e);
    }
  },
  logout: async () => {
    try {
      const { access_token } = JSON.parse(localStorage.getItem("token"));

      const request = new Request(`${APIURL}/auth/logout`, {
        method: "POST",
        headers: new Headers({
          "Content-Type": "application/json",
          locale: "fr",
          Authorization: "Bearer " + access_token,
        }),
      });

      await fetch(request);
      localStorage.clear();
      HEADERS.delete("Authorization");
    } catch (e) {
      console.log(e);
    }
  },
  checkAuth: () => {
    return localStorage.getItem("token") ? Promise.resolve() : Promise.reject();
  },
  checkError: (error) => {
    const status = error.status;
    const message = error.message;

    if (
      status === 401 ||
      status === 403 ||
      message.includes("Unauthenticated") ||
      message.includes("Failed to fetch")
    ) {
      localStorage.removeItem("token");
      return Promise.reject();
    }
    // other error code (404, 500, etc): no need to log out
    return Promise.resolve();
  },
  getPermissions: () => {
    if (localStorage.getItem("roles")) {
      const roles = JSON.parse(localStorage.getItem("roles"));
      return Promise.resolve(roles);
    }

    return Promise.reject();
  },
  getIdentity: () => {
    try {
      const { id, first_name, last_name } = JSON.parse(
        localStorage.getItem("me")
      );
      return Promise.resolve({ id, first_name, last_name });
    } catch (error) {
      return Promise.reject(error);
    }
  },
};
