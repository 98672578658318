import React from "react";
import { Layout } from "react-admin";
import CustomAppBar from "./CustomAppBar";
import { CustomMenu } from "./CustomMenu";

const CustomLayout = ({ children, ...props }) => (
  <Layout
    {...props}
    sx={{
      // main content (RaLayout-content)
      "& main, & .RaLayout-content": {
        pt: "16px",
      },
    }}
    appBar={CustomAppBar}
    menu={CustomMenu}
  >
    {children}
  </Layout>
);

export default CustomLayout;
