import { Button } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  button: {
    backgroundColor: "#4285f4",
    color: "white",
    "&:hover": {
      backgroundColor: "#3264b9",
      color: "white",
    },
  },
});

export function GoogleButton() {
  const classes = useStyles();
  const redirectUrl = `https://${window.location.hostname}`;

  return (
    <Button
      variant='contained'
      className={classes.button}
      color='secondary'
      href={`https://univrdev.com/auth/google/redirect?redirect_url=${redirectUrl}`}
      startIcon={
        <img
          src='https://lh3.googleusercontent.com/COxitqgJr1sJnIDe8-jiKhxDx1FrYbtRHKJ9z_hELisAlapwE9LUPh6fcXIfb5vwpbMl4xl9H9TRFPc5NOO8Sb3VSgIBrfRYvW6cUA'
          alt='Google Logo'
          style={{
            width: "30px",
            height: "30px",
            padding: "4px",
            backgroundColor: "white",
            borderRadius: "24px",
          }}
        />
      }
    >
      Se connecter avec Google
    </Button>
  );
}
