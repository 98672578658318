import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { makeStyles } from "@mui/styles";

interface CustomTitleProps {
  titles: string[];
}

const useStyles = makeStyles({
  container: {
    display: "flex",
    alignItems: "center",
  },
  h5: {
    margin: "0",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  icon: {
    fontSize: "1.7em",
  },
});

export const CustomTitle = ({ titles }: CustomTitleProps) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      {titles.map((t, index, self) => (
        <>
          <h5 className={classes.h5} key={index} >{t}</h5>
          {index < self.length - 1 && (
            <ChevronRightIcon color='primary' key={t} className={classes.icon} />
          )}
        </>
      ))}
    </div>
  );
};
