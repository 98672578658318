import { useState } from "react";
import { uploadFile } from "../services/uploader-service";

const useExperienceTransform = () => {
  const [progress, setProgress] = useState(null);

  /**
   * Transform experience data (including file uploads) before sending it to the API.
   * @param param0
   * @returns
   */
  const transform = async ({ build, images, videos, modules, ...data }) => {
    const progressCb = (prog) => setProgress(prog !== 0 ? prog : null);

    // handle build
    if (build?.rawFile) {
      build = await uploadFile(build.rawFile, progressCb);
    }

    // handle images
    if (images?.length > 0) {
      images = await Promise.all(
        images.map(async (image) => {
          if (image.rawFile) {
            return await uploadFile(image.rawFile, progressCb);
          }
          return image;
        })
      );
    }

    // handle videos
    if (videos?.length > 0) {
      videos = await Promise.all(
        videos.map(async (video) => {
          if (video.rawFile) {
            return await uploadFile(video.rawFile, progressCb);
          }
          return video;
        })
      );
    }

    // handle modules
    if (modules?.length > 0) {
      modules = await Promise.all(
        modules.map(async (module, index) => {
          // handle position
          module.position = index + 1;

          // handle module images (if any)
          if (module.images?.rawFile) {
            // for now, only one image per module (may change in the future)
            module.images = [
              await uploadFile(module.images.rawFile, progressCb),
            ];
          }

          return module;
        })
      );
    }

    return {
      ...data,
      modules,
      build_id: build?.id,
      images: images?.map((image) => image.id),
      videos: videos?.map((video) => video.id),
    };
  };

  return { transform, progress };
};

export default useExperienceTransform;
