import { APIURL } from "../global/global";
import Resumable from "resumablejs";

export const defaultResumableOptions: any = {
  simultaneousUploads: 1,
  uploadMethod: "POST",
  testChunks: false,
  chunkNumberParameterName: "chunk",
  totalChunksParameterName: "chunks",
  fileParameterName: "file",
  fileNameParameterName: "name",
  target: `${APIURL}/files`,
  method: "multipart",
  chunkSizeParameterName: "",
  identifierParameterName: "",
  currentChunkSizeParameterName: "",
  relativePathParameterName: "filename",
  totalSizeParameterName: "",
  parameterNamespace: "",
  typeParameterName: "",
  query: (file) => ({
    chunks: file.chunks.length + 1,
  }),
};

/**
 * Multi-Chunk uploader (using ResumableJS).
 */
class FileUploader {
  private uploader: Resumable;

  private uploadProgress = 0;
  private uploading = false;
  private responseFiles = [];
  private files = [];

  constructor(accessToken) {
    this.uploadProgress = 0;
    this.responseFiles = []; // files retrieved after push
    // setting up the uploader
    this.uploader = new Resumable({
      ...defaultResumableOptions,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    this.setupEventListeners();
  }

  /**
   * Add multiple files to the uploader's queue.
   * @param files
   */
  addFiles(files) {
    files.forEach((f) => this.addFile(f));
  }

  /**
   * Stop the upload.
   */
  stopUpload(shouldReinjectFiles?: boolean) {
    this.uploader.cancel();

    if (shouldReinjectFiles) this.addFiles(this.files); // reinject files into the uploader
  }

  pauseUpload() {
    this.uploader.pause();
  }

  /**
   * Add a file to the uploader's queue.
   * @param file
   */
  addFile(file) {
    this.uploader.addFile(file);
    this.files.push(file);
  }

  removeFile(file) {
    const uploaderFile = this.uploader.files.find(
      (f) => f.fileName === file.name
    );
    this.uploader.removeFile(uploaderFile);
    this.files = this.files.filter((f) => f.fileName !== file.fileName);
  }

  removeFiles(files) {
    const uploaderFiles = this.uploader.files.filter((f) =>
      files.find((file) => file.name === f.fileName)
    );
    uploaderFiles.forEach((f) => this.uploader.removeFile(f));
    this.files = this.uploader.files;
  }

  /**
   * Format query to be correctly handled by the UniVR API.
   * @param file
   * @private
   */
  private formatQuery(file) {
    return {
      chunks: file.chunks.length + 1,
    };
  }

  /**
   * Launch file uploads.
   */
  upload() {
    this.uploader.upload();
  }

  /**
   * Get response files (based on the file type).
   * @param type
   * @private
   */
  getResponseFiles(type) {
    let res = null;

    if (type) {
      res = this.responseFiles.filter((f) => f.mime.includes(type));
    } else {
      res = this.responseFiles;
    }

    return res;
  }

  hasFiles() {
    return this.uploader.files.length > 0;
  }

  /**
   * Setup all usefull listeners from the uploader.
   * @private
   */
  private setupEventListeners() {
    this.uploader.on("fileAdded", (file, event) => {
      console.log("File added!", file);
    });

    this.uploader.on("uploadStart", () => {
      console.log("Upload start");
      this.uploading = true;
    });

    this.uploader.on("fileProgress", (file) => {
      console.log("Progress: " + file.progress(true));
      this.uploadProgress = file.progress(true) * 100;
    });

    this.uploader.on("fileSuccess", (file, event) => {
      console.log(event);
      if (event) this.responseFiles.push(JSON.parse(event).result.original);
    });

    this.uploader.on("complete", () => {
      console.log("Upload complete!");
      this.uploading = false;
    });

    this.uploader.on("error", (message, file) => {
      console.error(message);
      this.uploading = false;
    });
  }

  public getUploadProgress() {
    return this.uploadProgress;
  }

  public setUploadProgress(uploadProgress): void {
    this.uploadProgress = uploadProgress;
  }

  public isUploading() {
    return this.uploading;
  }

  public setUploading(uploading): void {
    this.uploading = uploading;
  }

  public setResponseFiles(responseFiles): void {
    this.responseFiles = responseFiles;
  }
}

export default FileUploader;
