import { createTheme } from "@mui/material";

const palette = {
  // mode:"light",
  background: {
    default: "#fafafb",
  },
  primary: {
    main: "#9c27b0",
    light: "#AF52BF",
    dark: "#6D1B7B",
    contrastText: "#fff",
  },
  secondary: {
    light: "#6ec6ff",
    main: "#2196f3",
    dark: "#0069c0",
    contrastText: "#fff",
  },
};

export const theme = createTheme({
  palette: {
    ...palette,
  },
  spacing: 8,
  shape: { borderRadius: 8 },
  typography: {
    h6: {
      fontWeight: 400,
    },
  },
  components: {
    MuiTextField: {
      defaultProps: {
        variant: "outlined" as const,
        margin: "normal" as const,
        size: "medium" as const,
      },
    },
    MuiFormControl: {
      defaultProps: {
        variant: "outlined" as const,
        margin: "normal" as const,
        size: "medium" as const,
      },
    },
    // override MuiButton (contained) default style
    MuiButton: {
      styleOverrides: {
        containedPrimary: {
          // hover
          "&:hover": {
            // main color
            color: palette.primary.contrastText,
            backgroundColor: palette.primary.dark,
          },
        },
        textPrimary: {
          // hover
          "&:hover": {
            // main color
            color: palette.primary.contrastText,
            backgroundColor: palette.primary.dark,
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          "&:hover": {
            color: palette.primary.main,
          },
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          "&:hover": {
            color: palette.primary.main,
          },
        },
      },
    },
  },
});
