import { Edit } from "react-admin";
import UserForm from "./UserForm";
import { useParams } from "react-router-dom";

const UserEdit = (props) => {
  const { id, userId } = useParams();

  return (
    <Edit
      resource='users'
      id={userId}
      redirect={`/organizations/${id}/users`}
    >
      <UserForm />
    </Edit>
  );
};

export default UserEdit;
