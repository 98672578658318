import * as React from "react";
import { AppBar, useRedirect } from "react-admin";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";
import logo from "../../assets/images/UNIVR-BLANC.svg";
import { Box, Toolbar, useMediaQuery } from "@mui/material";
import Breadcrumb from "./Breadcrumb";

const useStyles = makeStyles({
  toolbar: {
    display: "grid",
    gridTemplateColumns: "3fr 1fr 3fr",
    width: "100%",
    background: "#1c1c1c",
  },
  logo: {
    justifySelf: "center",
    width: "64px",
  },
  title: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
});

const CustomAppBar = (props) => {
  // bp: breakpoint
  const matches = useMediaQuery("(min-width:600px)");
  const redirect = useRedirect();

  const classes = useStyles();
  return (
    <AppBar
      userMenu={false}
      sx={{
        backgroundColor: "#1c1c1c",
        height: "64px",
        display: "flex",
        justifyContent: "center",
      }}
    >
      {matches && (
        <Box
          ml={4}
          mr={8}
          sx={{
            cursor: "pointer",
            transition: "opacity 0.2s ease-in-out",
            opacity: 1,
            "&:hover": {
              opacity: 0.9,
            }
          }}
          onClick={() => redirect("/")}
        >
          <img src={logo} alt='logo' className={classes.logo} />
        </Box>
      )}
      <Box flex={1}>
        <Breadcrumb />
      </Box>
    </AppBar>
  );
};

export default CustomAppBar;
