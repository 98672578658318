import { Card, CardHeader, CardContent, Container, Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { useEffect, useState } from "react";
import { Loading } from "react-admin";
import dataProvider from "../../data-providers/data-provider";
import { formatDate } from "../../global/global";
import { ResourceType } from "../../models/resource";
import { IRole } from "../../models/role";
import { CustomDivider } from "./CustomDivider";
import { CustomTitle } from "./CustomTitle";

/**
 * Styles
 */

const useStyles = makeStyles({
  card: {
    height: "100%",
    width: "100%",
    borderRadius: "16px",
    boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
    backdropFilter: "blur(5px)",
    "-webkit-backdrop-filter": "blur(5px)",
    border: "1px solid rgba(255, 255, 255, 0.3)",
  },
  header: {
    background: "#ebebeb",
  },
  container: {
    display: "flex",
  },
  mainInfo: {
    flex: "1",
    color: "#a12fa1",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    marginRight: "1em",
  },
});

/**
 * Interfaces
 */

interface BaseContainerProps {
  data: string[];
}

interface DashboardCardProps {
  resource: ResourceType;
  amount?: number;
  organizationId?: number;
  roles?: IRole[];
}

/**
 * Components
 */

const BaseContainer = ({ data }: BaseContainerProps) => {
  const classes = useStyles();
  return (
    <Container className={classes.container}>
      {data.map((d, index, arr) => (
        <Typography
          className={index < arr.length - 1 ? classes.mainInfo : null}
          key={d}
        >
          {d}
        </Typography>
      ))}
    </Container>
  );
};

export const DashboardCard = ({
  resource,
  amount,
  organizationId,
  roles,
}: DashboardCardProps) => {
  const classes = useStyles();
  const [resourceData, setResourceData] = useState<any[] | null>(null);

  useEffect(() => { 
    dataProvider
      .getList(`${resource}/last`, {
        amount: amount ? amount : 5,
        organization_id: organizationId,
      })
      .then(({ data }) => {
        setResourceData(
          data.map((d) => ({ ...d, updated_at: formatDate(d.updated_at) }))
        );
      })
      .catch((err) => console.error(err));
  }, [amount, organizationId, resource]);

  if (!resourceData) return <Loading />;

  const getContainer = (rd) => {
    const data = [];

    switch (resource) {
      case "experiences":
      case "organizations":
        data.push(rd.name, rd.updated_at);
        break;
      case "users":
        const roleName = roles.find((r) => r.id === rd.roles[0])?.name;

        data.push(
          `${rd.first_name} ${rd.last_name}`,
          roleName,
          rd.updated_at
        );
        break;
      case "licenses":
        data.push(rd.organization?.name, rd.experience?.name, rd.updated_at);
        break;
    }

    return <BaseContainer key={rd.id} data={data} />;
  };

  const getTitle = () => {
    switch (resource) {
      case "experiences":
        return ["Expériences", "Dernières mises à jour"];
      case "organizations":
        return ["Organisations", "Dernières mises à jour"];
      case "users":
        return ["Utilisateurs", "Dernières mises à jour"];
      case "licenses":
        return ["Licences", "Dernières mises à jour"];
      default:
        return [];
    }
  };
  return (
    <Card className={classes.card}>
      <CardHeader
        title={<CustomTitle titles={getTitle()} />}
        className={classes.header}
      />

      <CardContent>
        {resourceData.length > 0 &&
          resourceData.map((rd, index, arr) => (
            
            <>
              {getContainer(rd)}
              {index < arr.length - 1 && <CustomDivider key={rd.id*0.0005} />}
            </>
          ))}

        {resourceData.length === 0 && <Typography>Aucune donnée</Typography>}
      </CardContent>
    </Card>
  );
};
