/* eslint-disable import/no-anonymous-default-export */
import { fetchUtils } from "react-admin";

import {
  APIURL,
  HEADERS,
  flattenObject,
  stringifyQuery,
} from "../global/global";
import { stringify } from "query-string";

const httpClient = (url, options: any = {}) => {
  const tokenString = localStorage.getItem("token");

  if (!HEADERS.has("Authorization") && tokenString) {
    const { access_token } = JSON.parse(tokenString);
    HEADERS.set("Authorization", `Bearer ${access_token}`);
  }

  options.headers = HEADERS;

  return fetchUtils.fetchJson(url, options);
};

export default {
  getList: (resource: string, params?: any, ...args) => {
    let url = `${APIURL}/${resource}`;

    if (params && !resource.includes("roles")) {
      const query = flattenObject(params);
      url += `?${stringifyQuery(query)}`;
    }

    return httpClient(url).then(({ json }) => ({
      data: json.data ? json.data : json,
      total: json.total
        ? json.total
        : json.length
        ? json.length
        : json.meta
        ? json.meta.total
        : 0,
      page: json.page ? json.page : json.meta ? json.meta.current_page : 1,
      perPage: json.perPage
        ? json.perPage
        : json.meta
        ? json.meta.per_page
        : undefined,
    }));
  },

  getOne: (resource: string, params?: any) => {
    let url = `${APIURL}/${resource}`;
    if (params) url = `${url}/${params.id}`;
    return httpClient(url).then(({ json }) => ({
      data: json,
    }));
  },

  getMany: (resource: string, params?: any) => {
    let query = {};
    if (params) query = params;

    const url = `${APIURL}/${resource}?${stringify(query)}`;

    return httpClient(url).then(({ json }) => ({
      data: json,
    }));
  },

  getManyReference: (resource, params) => {
    throw "Not implemented";
  },

  update: (resource: string, params?: any) => {
    return httpClient(`${APIURL}/${resource}/${params.id}`, {
      method: "POST",
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({
      data: json,
    }));
  },

  updateMany: (resource, params) => {
    throw "Not implemented";
  },

  create: (resource: string, params?: any) => {
    return httpClient(`${APIURL}/${resource}`, {
      method: "POST",
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({
      data: { ...params.data, id: json.id },
    }));
  },

  delete: (resource: string, params?: any) => {
    return httpClient(`${APIURL}/${resource}/${params.id}`, {
      method: "DELETE",
    }).then(({ json }) => ({ data: json }));
  },

  deleteMany: (resource, params) => {
    throw "Not implemented";
  },
};
