import {
  FormDataConsumer,
  FormTab,
  TextInput,
  useRecordContext,
} from "react-admin";
import { RichTextInput } from "ra-input-rich-text";
import { incrementVersion } from "../../../../utils/utils";
import { FormControlLabel, Switch } from "@mui/material";
import { useState } from "react";

export const VersionFormTab = (props) => {
  const record = useRecordContext();

  const { version, description } = (() => {
    const latestVersion = record?.updates?.[0];
    if (!latestVersion)
      return { version: "1.1", description: "Version initiale" };
    return {
      version: incrementVersion(latestVersion.version.toString()),
      description: "Correction de bugs mineurs et améliorations.",
    };
  })();

  // mandatory if no version
  const newVersionMandatory = version === "1.1";

  const [showNewVersion, setShowNewVersion] = useState(newVersionMandatory);

  return (
    <FormDataConsumer>
      {({ formData }) => {
        // show if rawFile is defined or if newVersionMandatory is true
        setShowNewVersion(formData?.build?.rawFile !== undefined || showNewVersion);

        return (
          <FormTab {...props} label='Version'>
            {!newVersionMandatory && (
              <FormControlLabel
                control={
                  <Switch
                    checked={showNewVersion}
                    onChange={(e) => setShowNewVersion(e.target.checked)}
                  />
                }
                label='Créer une nouvelle mise à jour ?'
              />
            )}

            {showNewVersion && (
              <>
                {/* Version field */}
                <TextInput
                  source='update.version'
                  label='Version'
                  defaultValue={version}
                />

                {/* Description field */}
                <RichTextInput
                  source='update.description'
                  label='Description'
                  defaultValue={description}
                />
              </>
            )}
          </FormTab>
        );
      }}
    </FormDataConsumer>
  );
};
