import makeStyles from "@mui/styles/makeStyles";
import axios from "axios";
import { useEffect } from "react";
import { Login } from "react-admin";
import backgroundImage from "../../assets/images/univr-background.png";
import { APIURL } from "../../global/global";
import { theme } from "../../global/styles";
import { CustomLoginForm } from "./CustomLoginForm";

const CustomLogin = (props) => {
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);

    if (!urlParams.has("token")) return;

    (async () => {
      const token = urlParams.get("token");
      const info = await axios
        .get(`${APIURL}/auth/info`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => res.data);

      const { access_token, roles, user, organization } = info;

      localStorage.setItem("token", JSON.stringify({ access_token }));
      localStorage.setItem("roles", JSON.stringify(roles));
      localStorage.setItem("me", JSON.stringify(user));
      localStorage.setItem("organization", JSON.stringify(organization));

      window.location.href = "/";
    })();
  }, []);

  return (
    <Login
      {...props}
      backgroundImage={backgroundImage}
      sx={{
        // card
        "& .MuiCard-root": {
          width: "100%",
          maxWidth: "32em",
        },
      }}
    >
      <CustomLoginForm />
    </Login>
  );
};

export default CustomLogin;
