import { useEffect, useState } from "react";
import {
  ChipField,
  Loading,
  NumberInput,
  PasswordInput,
  SelectArrayInput,
  SimpleForm,
  TextInput,
  usePermissions,
  useRecordContext,
  useRedirect,
} from "react-admin";
import dataProvider from "../../data-providers/data-provider";
import { hasAccess } from "../../global/permissions";
import { IRole } from "../../models/role";
import { useParams } from "react-router-dom";
import { FormControlLabel, Switch } from "@mui/material";

interface IOrganizationInput {
  id: number;
  name: string;
}

const UserForm = () => {
  const [roles, setRoles] = useState<IRole[]>([]);
  const [organizations, setOrganizations] = useState<IOrganizationInput[]>([]);
  const userOrga = JSON.parse(localStorage.getItem("organization"));
  const redirect = useRedirect();
  const { permissions } = usePermissions();
  const { id: organization_id } = useParams();
  const record = useRecordContext();
  const [showPassowordInput, setShowPasswordInput] = useState<boolean>(
    record?.id ? false : true
  );
  const firstTime = record?.id ? false : true;

  useEffect(() => {
    // roles
    if (roles.length === 0)
      dataProvider
        .getList("roles")
        .then(({ data }) => {
          let roles = data;
          if (!permissions.includes("admin"))
            roles = roles.filter((r) => r.id !== "admin");
          setRoles(roles);
        })
        .catch((err) => console.error(err));

    // organizations
    if (userOrga && organizations.length === 0) {
      dataProvider
        .getList("organizations")
        .then(({ data }) => {
          let result = data.map((o) => ({ id: o.id, name: o.name }));

          if (!permissions.includes("admin"))
            result = data.filter((o) => o.id === userOrga.id);
          result.sort((a, b) =>
            a.name.toLowerCase() > b.name.toLowerCase()
              ? 1
              : b.name.toLowerCase() > a.name.toLowerCase()
              ? -1
              : 0
          );
          setOrganizations(result);
        })
        .catch((err) => console.error(err));
    }
  }, [userOrga, organizations.length, permissions, roles.length]);

  /**
   * Validate data.
   * @param values
   */
  const validateForm = (values) => {
    const errors = {};

    if (!values.first_name) {
      errors["first_name"] = "Ce champ est obligatoire";
    }

    if (!values.last_name) {
      errors["last_name"] = "Ce champ est obligatoire";
    }

    if (!values.email) {
      errors["email"] = "Ce champ est obligatoire";
    }

    if (!values.organization_id) {
      errors["organization_id"] = "Ce champ est obligatoire";
    }

    if (!values.roles || values.roles.length === 0) {
      errors["roles"] = "Ce champ est obligatoire";
    }

    if (!values.password && firstTime) {
      errors["password"] = "Ce champ est obligatoire";
    }

    return errors;
  };

  if (
    roles.length === 0 ||
    organizations.length === 0 ||
    !userOrga ||
    !permissions
  )
    return <Loading />;

  if (!hasAccess({ name: "users", crudType: "edit" }, permissions))
    redirect("/");

  return (
    <SimpleForm validate={validateForm} defaultValues={{ name: "default" }}>
      <TextInput source='first_name' label='Prénom' required />

      <TextInput source='last_name' label='Nom' required />

      <TextInput source='email' label='Email' required />

      <SelectArrayInput label='Rôles' source='roles' choices={roles}>
        <ChipField source='id' />
      </SelectArrayInput>

      <NumberInput
        source='organization_id'
        defaultValue={organization_id}
        required
        sx={{ display: "none" }}
      />

      {!firstTime && (
        <FormControlLabel
          control={
            <Switch
              checked={showPassowordInput}
              defaultChecked={showPassowordInput}
              onChange={() => setShowPasswordInput(!showPassowordInput)}
            />
          }
          label='Modifier le mot de passe ?'
        />
      )}

      {showPassowordInput && (
        <PasswordInput
          source='password'
          label='Mot de passe'
          required={firstTime}
        />
      )}
    </SimpleForm>
  );
};

export default UserForm;
