import {
  Datagrid,
  DateField,
  EditButton,
  Filter,
  List,
  Loading,
  ReferenceField,
  TextField,
  TextInput,
  usePermissions,
  useRedirect,
} from "react-admin";
import { hasAccess } from "../../global/permissions";
import { CustomPagination } from "../Layout/CustomPagination";
import { CustomTitle } from "../Layout/CustomTitle";
import LicenseListButton from "../Licenses/Buttons/LicenseListButton";

const ExperienceFilter = ({ permissions, ...props }) => (
  <Filter {...props}>
    <TextInput
      label='Organisation'
      variant='outlined'
      placeholder='Organisation'
      source='organization'
      alwaysOn
      disabled={!permissions.includes("admin")}
    />
    <TextInput
      label='Nom'
      variant='outlined'
      placeholder='Nom'
      source='name'
      alwaysOn
    />
  </Filter>
);

const ExperienceList = () => {
  const organization = JSON.parse(localStorage.getItem("organization"));
  const redirect = useRedirect();
  const { permissions } = usePermissions();

  if (!organization || !permissions) return <Loading />;

  if (!hasAccess({ name: "experiences", crudType: "list" }, permissions))
    redirect("/");

  const filterDefaultValues = !permissions.includes("admin")
    ? { organization: organization.name }
    : null;

  return (
    <List
      hasCreate
      exporter={false}
      perPage={50}
      filters={<ExperienceFilter permissions={permissions} />}
      sort={{ field: "updated_at", order: "DESC" }}
      filterDefaultValues={filterDefaultValues}
    >
      <Datagrid bulkActionButtons={false}>
        <TextField source='id' label='ID' />
        <TextField source='name' label='Nom' />
        <ReferenceField
          label='Organisation'
          source='organization_id'
          reference='organizations'
        >
          <TextField source='name' />
        </ReferenceField>
        <DateField source='updated_at' label='Dernière mise à jour' showTime />
        <LicenseListButton parentResource="experiences" />
        <EditButton variant='contained' label='Editer' />
      </Datagrid>
    </List>
  );
};

export default ExperienceList;
